import * as React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/seo";
import Footer from "../components/FooterSection";
import {
  ProductPagesDataQuery,
  StrapiProductPagesContentQuoteImages,
  StrapiProductPagesContentTextBlocks,
  StrapiProductPagesHeaderImages,
  StrapiProductPagesHeaderTexts,
} from "../../graphqlTypes";
import { DeepNonNullable } from "ts-essentials";
import { StrapiProductPagesComponent } from "../types/productpages";
import { GatsbyImage } from "gatsby-plugin-image";
import { mdxBody } from "@utils/mdxUtils";
import { MDXProvider } from "@mdx-js/react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import GridList from "@ui/ProductPages/GridList";
import GridListLeft from "@ui/ProductPages/GridListLeft";
import GridListRight from "@ui/ProductPages/GridListRight";
import SignupModal from "@ui/Modals/SignupModal";
import { useRef } from "react";

const MDXMapping = {
  GridList,
  GridListLeft,
  GridListRight,
};

const createHeaderUI = (
  item: DeepNonNullable<StrapiProductPagesComponent["header"]>[number],
  image: StrapiProductPagesHeaderImages,
  text: StrapiProductPagesHeaderTexts,
  modalSignupRef: any
) => {
  if ("strapi_component" in item) {
    if (item.strapi_component === "product-pages.hero") {
      return (
        <div className="grid grid-cols-9 gap-4">
          <div className="col-span-9 md:col-span-4 mt-16">
            <h1 className="text-white mb-4">{item.title}</h1>
            <h2 className="text-white mb-14">{item.subTitle}</h2>
            {text && (
              <p className="text-white">
                <MDXRenderer>{mdxBody(text.mdxText)}</MDXRenderer>
              </p>
            )}
          </div>
          {image && (
            <div className="col-span-9 md:col-span-5 md:mt-16">
              <GatsbyImage
                alt={item.image.alternativeText}
                image={image.localFile.childImageSharp.gatsbyImageData}
              />
            </div>
          )}
        </div>
      );
    } else if (item.strapi_component === "product-pages.call-to-action") {
      return (
        <div className="text-center my-8 bg-accuRankerPurple-500 rounded-xl p-12 shadow-2xl">
          <p className="text-white text-4xl">{item.title}</p>
          <p className="text-white text-xl">{item.subTitle}</p>
          <button
            className="rounded-md px-8 py-4 text-white font-medium transition-colors duration-300 bg-accuRankerOrange-500 hover:bg-accuRankerOrange-900"
            onClick={() => modalSignupRef.current.openModal()}
          >
            {item.buttonText}
          </button>
        </div>
      );
    }
  }
};

const createContentUI = (
  item: DeepNonNullable<StrapiProductPagesComponent["content"]>[number],
  quoteImage: StrapiProductPagesContentQuoteImages,
  textBlock: StrapiProductPagesContentTextBlocks,
  modalSignupRef: any
) => {
  if ("strapi_component" in item) {
    if (item.strapi_component === "product-pages.quote") {
      return (
        <div className="max-w-7xl mx-auto mb-16 shadow-lg border-2 rounded-xl">
          <figure className="md:flex bg-gray-100 rounded-xl p-8 md:p-0 mb-0">
            {!quoteImage.localFile.childImageSharp &&
            quoteImage.localFile.extension === "svg" ? (
              <img
                src={quoteImage.localFile.publicURL}
                alt={item.personImage.alternativeText}
                className="w-32 h-32 md:w-96 md:w-[250px] md:h-auto md:rounded-none md:rounded-l-xl rounded-full mx-auto"
              />
            ) : (
              <div>
                <GatsbyImage
                  key={item.personImage.id}
                  image={quoteImage.localFile.childImageSharp.gatsbyImageData}
                  alt={item.personImage.alternativeText}
                  className="w-32 h-32 md:w-[250px] md:h-full md:rounded-none md:rounded-l-xl rounded-full mx-auto"
                />
              </div>
            )}

            <div className="pt-6 md:p-8 text-center md:text-left space-y-4">
              <blockquote className="md:ml-0">
                <p className="text-lg font-semibold">“{item.quote}”</p>
              </blockquote>
              <figcaption className="font-medium">
                <div>{item.personName}</div>
                <div className="text-gray-500">{item.personTitle}</div>
              </figcaption>
            </div>
          </figure>
        </div>
      );
    } else if (item.strapi_component === "product-pages.call-to-action") {
      return (
        <div className="text-center my-8">
          <p className="text-4xl">{item.title}</p>
          <p className="text-xl">{item.subTitle}</p>
          <button
            className="rounded-md px-8 py-4 text-white font-medium transition-colors duration-300 bg-accuRankerOrange-500 hover:bg-accuRankerOrange-900"
            onClick={() => modalSignupRef.current.openModal()}
          >
            {item.buttonText}
          </button>
        </div>
      );
    } else if (item.strapi_component === "product-pages.text-block") {
      return (
        <div className="max-w-7xl mx-auto mb-16 shadow-lg border-2 rounded-xl px-12 pt-12 pb-6">
          <MDXProvider components={MDXMapping}>
            <MDXRenderer>{mdxBody(textBlock.mdxText)}</MDXRenderer>
          </MDXProvider>
        </div>
      );
    }
  }
};

export const query = graphql`
  query ProductPagesData($slug: String!) {
    strapiProductPages(slug: { eq: $slug }) {
      pageTitle
      header
      headerTexts {
        mdxText {
          childMdx {
            body
          }
        }
      }
      headerImages {
        localFile {
          extension
          publicURL
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      content
      contentQuoteImages {
        localFile {
          extension
          publicURL
          childImageSharp {
            gatsbyImageData(width: 250)
          }
        }
      }
      contentTextBlocks {
        mdxText {
          childMdx {
            body
          }
        }
      }
    }
  }
`;

const ProductPage = ({
  data,
}: {
  data: DeepNonNullable<ProductPagesDataQuery>;
}) => {
  const {
    pageTitle,
    headerImages,
    headerTexts,
    contentQuoteImages,
    contentTextBlocks,
  } = data.strapiProductPages;

  const modalSignupRef = useRef();

  const headerItems: DeepNonNullable<StrapiProductPagesComponent["header"]> =
    data.strapiProductPages.header;

  const headerItemsUI = headerItems.map((item, ix) =>
    createHeaderUI(item, headerImages[ix], headerTexts[ix], modalSignupRef)
  );

  const contentItems: DeepNonNullable<StrapiProductPagesComponent["content"]> =
    data.strapiProductPages.content;

  const contentItemsUI = contentItems.map((item, ix) =>
    createContentUI(
      item,
      contentQuoteImages[ix],
      contentTextBlocks[ix],
      modalSignupRef
    )
  );

  return (
    <Layout>
      <Seo title={pageTitle} />
      <div className="flex flex-col mx-auto px-4 xl:px-0 mt-4 mb-4">
        {headerItemsUI}
      </div>
      <div className="full-width-strip bg-accuRankerCream w-full py-12">
        {contentItemsUI}
        <div className="max-w-7xl mx-auto px-4 xl:px-0 mt-4 mb-4"></div>
      </div>
      <SignupModal ref={modalSignupRef} />
      <Footer />
    </Layout>
  );
};

export default ProductPage;
